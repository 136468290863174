import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentFrame from "../components/ContentFrame";
import Button from "../components/Button/Button";
import PreviewAktivitaet from "../components/PreviewAktivitaet";
import PreviewAktivitaetPublikation from "../components/PreviewAktivitaet/PreviewAktivitaetPublikation";
import BlockTextImage from "../components/BlockTextImage";
import ProfilfeldLinks from "../components/ProfilfeldLinks";
import MeldungPreview from "../components/MeldungPreview";

function Kompetenzfeld({ data }) {
  const kompetenzfeld = data.strapiKompetenzfeld;
  const aktivitaets = data.allStrapiAktivitaet;
  const meldungen = data.allWpPost;
  const availableKats = [
    "Publikationen",
    "Lehrveranstaltungen",
    "Unternehmensgründungen",
    "Qualifikationsarbeiten",
    "Aktuelles",
  ];
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={kompetenzfeld.Titel}
        //{

        // kompetenzfeld.Kurzbeschreibung && kompetenzfeld.Kurzbeschreibung > 150
        //  ? kompetenzfeld.Kurzbeschreibung.substring(0, 150)
        // : kompetenzfeld.Kurzbeschreibung
        //}
      />
      <section id="hero" style={{ zIndex: "1" }}>
        <div className="grid lg:grid-cols-2">
          <div className="text-white pt-12 pb-10 bg-primary container mx-auto relative">
            <div className="sm:flex relative mx-auto z-10 flex-col w-full md:w-4/5 sm:justify-center items-start text-center md:text-left">
              <p className="font-light w-full tracking-wide capitalize">
                PROFILFELD
              </p>
              <h1
                className={`my-5 w-full break-words sm:text-3xl text-2xl md:text-left text-center sm:block md:text-5xl font-header font-light break-word leading-tight tracking-wide`}
              >
                <b>{kompetenzfeld.Titel}:</b> {kompetenzfeld.Kurzbeschreibung}
              </h1>

              <p className="font-light mt-4 md:w-2/3">
                <ProfilfeldLinks
                  isWhite={true}
                  edges={data.allStrapiKompetenzfeld.edges}
                />
              </p>
            </div>
            {kompetenzfeld.Titelbild && (
              <Img
                className="top-0 bottom-0 right-0 left-0 opacity-50"
                style={{ position: "absolute", zIndex: 0 }}
                fluid={
                  kompetenzfeld.Titelbild.localFile?.childImageSharp?.fluid
                }
              />
            )}
            <div className="w-full md:w-3/5 py-6 text-center"></div>
          </div>
          <div className="pt-48 pb-10 container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <nav className="flex flex-col w-full lg:w-3/5 lg:pl-10 justify-center items-start text-center md:text-left">
              <ul className="font-light text-lg space-y-3 mx-auto">
                <li>
                  <Button
                    isAnchor="true"
                    to={`/${kompetenzfeld.Slug}#ueber-kompetenzfeld`}
                  >
                    Über das Profilfeld
                  </Button>
                </li>
                {availableKats.map((kat) => {
                  return aktivitaets.nodes.filter((aktivitaet) => {
                    return aktivitaet.aktivitatskategories.includes({
                      Titel: kat,
                    });
                  }) ? (
                    <li>
                      <Button
                        isAnchor="true"
                        to={`/${kompetenzfeld.Slug}#${kat}`}
                      >
                        {kat}
                      </Button>
                    </li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <section id="ueber-kompetenzfeld">
        <div className="container mx-auto mt-10">
          {kompetenzfeld.Content.map((block) => {
            console.log(block);
            return <BlockTextImage key={block.id} {...block} />;
          })}
        </div>
      </section>
      <ContentFrame>
        <article>
          <section id="Publikationen">
            <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-6">
              <div className="col-span-2 row-span-2 p-6 bg-primary text-white font-light flex flex-col">
                <h2 className="font-header break-all text-3xl mb-4">
                  Publikationen.
                </h2>
                <p className="text-xl">
                  Auswahl unserer Veröffentlichungen zum Thema{" "}
                  <b>{kompetenzfeld.Titel}.</b>
                </p>
                <Button
                  to="/forschung"
                  className="text-lg"
                  style={{ marginTop: "auto" }}
                >
                  Zu allen Publikationen
                </Button>
              </div>

              {aktivitaets.nodes.map((aktivitaet) => {
                if (
                  aktivitaet.aktivitatskategories.some(
                    (key) => key["Titel"] === "Publikation"
                  ) &&
                  aktivitaet.aktivitatskategories.length === 1 // So wird sichergestellt, dass nur die Top-5-Publikationen, die nur mit Publikation getagged sind, vorn angezeigt werden
                ) {
                  return (
                    <PreviewAktivitaetPublikation
                      className="col-span-2"
                      data-sal="slide-up"
                      data-sal-delay={`200`}
                      data-sal-easing="ease-in-out"
                      data-sal-duration="5000"
                      datum={aktivitaet.Datum}
                      imErscheinen={aktivitaet.imErscheinen}
                      slug={aktivitaet.Slug}
                      titel={aktivitaet.Titel}
                      kurzbeschreibung={aktivitaet.Kurzbeschreibung}
                      aktivitaetskategories={aktivitaet.aktivitatskategories}
                      autoren={aktivitaet.people}
                    />
                  );
                }
              })}
            </div>
          </section>

          <section id="Lehrveranstaltungen">
            <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-6 mt-12">
              <div className="col-span-2 row-span-2 p-6 bg-primary text-white font-light flex flex-col">
                <h2 className="font-header sm:text-3xl text-2xl mb-4 break-words">
                  Lehrver-<br></br>anstaltungen.
                </h2>
                <p className="text-xl">
                  Unsere Lehrveranstaltungen zum Profilfeld{" "}
                  <b>{kompetenzfeld.Titel}.</b>
                </p>
                <Button
                  to="/forschung"
                  className="text-lg mt-4"
                  style={{ marginTop: "auto" }}
                >
                  Zu allen Lehrveranstaltungen
                </Button>
              </div>
              {aktivitaets.nodes.map((aktivitaet) => {
                if (
                  aktivitaet.aktivitatskategories.some(
                    (key) => key["Titel"] === "Lehrveranstaltung"
                  )
                ) {
                  return (
                    <PreviewAktivitaetPublikation
                      className="col-span-2"
                      excerptLength={200}
                      data-sal="slide-up"
                      data-sal-delay={`200`}
                      data-sal-easing="ease-in-out"
                      data-sal-duration="5000"
                      datum={aktivitaet.Datum}
                      imErscheinen={aktivitaet.imErscheinen}
                      slug={aktivitaet.Slug}
                      titel={aktivitaet.Titel}
                      kurzbeschreibung={aktivitaet.Kurzbeschreibung}
                      aktivitaetskategories={aktivitaet.aktivitatskategories}
                      autoren={aktivitaet.people}
                    />
                  );
                }
              })}
            </div>
          </section>

          <section id="Qualifikationsarbeiten">
            <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-6 mt-12">
              <div className="col-span-2 row-span-2 p-6 bg-primary text-white font-light">
                <h2 className="font-header text-2xl mb-4 break-words">
                  Qualifikations-<br></br>arbeiten.
                </h2>
                <p className="text-xl">
                  Unsere Qualifikationsarbeiten im Profilfeld{" "}
                  <b>{kompetenzfeld.Titel}.</b>
                </p>
              </div>
              {aktivitaets.nodes.map((aktivitaet) => {
                if (
                  aktivitaet.aktivitatskategories.some(
                    (key) => key["Titel"] === "Qualifikationsarbeit"
                  )
                ) {
                  return (
                    <PreviewAktivitaet
                      className="col-span-2 h-full"
                      excerptLength={200}
                      data-sal="slide-up"
                      data-sal-delay={`200`}
                      data-sal-easing="ease-in-out"
                      data-sal-duration="5000"
                      datum={aktivitaet.Datum}
                      slug={aktivitaet.Slug}
                      titel={aktivitaet.Titel}
                      kurzbeschreibung={aktivitaet.Kurzbeschreibung}
                      aktivitaetskategories={aktivitaet.aktivitatskategories}
                    />
                  );
                }
              })}
            </div>
          </section>

          <section id="Unternehmensgründungen">
            <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-6 mt-12">
              <div className="col-span-2 row-span-2 p-6 bg-primary text-white font-light">
                <h2 className="font-header text-2xl mb-4 break-words">
                  Unternehmens-<br></br>gründungen.
                </h2>
                <p className="text-xl">
                  Gründungen von Alumni im Profilfeld{" "}
                  <b>{kompetenzfeld.Titel}.</b>
                </p>
              </div>
              {aktivitaets.nodes.map((aktivitaet) => {
                if (
                  aktivitaet.aktivitatskategories.some(
                    (key) => key["Titel"] === "Unternehmensgründung"
                  )
                ) {
                  return (
                    <PreviewAktivitaet
                      className="col-span-2"
                      excerptLength={200}
                      data-sal="slide-up"
                      data-sal-delay={`200`}
                      data-sal-easing="ease-in-out"
                      data-sal-duration="5000"
                      datum={aktivitaet.Datum}
                      slug={aktivitaet.Slug}
                      titel={aktivitaet.Titel}
                      kurzbeschreibung={aktivitaet.Kurzbeschreibung}
                      aktivitaetskategories={aktivitaet.aktivitatskategories}
                    />
                  );
                }
              })}
            </div>
          </section>

          <section id="Aktuelles">
            <div className="container my-auto py-10 grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="">
                <h2 className="font-header font-light text-4xl">Aktuelles.</h2>
                <p className="font-light text-lg leading-loose">
                  Aktuelle Meldungen zum Profilfeld {kompetenzfeld.Titel}.
                </p>
                <Button to="/aktuelles" className="font-xl mt-4">
                  alle Meldungen
                </Button>
              </div>
              {meldungen.edges.map(({ node }) => (
                <MeldungPreview node={node} />
              ))}
            </div>
          </section>
        </article>
      </ContentFrame>
    </Layout>
  );
}

Kompetenzfeld.propTypes = {
  data: PropTypes.any,
};

export const query = graphql`
  query ($slug: String!, $camel: String!) {
    allWpPost(
      limit: 8
      filter: { strapiConnect: { profilfeld: { eq: $camel } } }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD.MM.YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    strapiKompetenzfeld(Slug: { eq: $slug }) {
      Slug
      Titel
      Kurzbeschreibung
      Titelbild {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      Content
    }

    allStrapiKompetenzfeld(
      filter: { Slug: { ne: $slug } }
      sort: { fields: Titel }
    ) {
      edges {
        node {
          Slug
          Titel
        }
      }
    }

    allStrapiAktivitaet(
      filter: { kompetenzfelds: { elemMatch: { Slug: { eq: $slug } } } }
    ) {
      nodes {
        Titel
        Slug
        Kurzbeschreibung
        imErscheinen
        Datum(formatString: "Y")
        aktivitatskategories {
          Titel
        }
        people {
          Vorname
          Nachname
        }
      }
    }
  }
`;

export default Kompetenzfeld;
